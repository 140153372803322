import { call, put, all, takeLatest } from 'redux-saga/effects'

import { 
  REQUEST_OFFICE_CONFIG,
  REQUEST_EDIT_OFFICE_CONFIG,
  REQUEST_PAYMENTS_BY_OFFICE_ID,
  REQUEST_USERS_BY_OFFICE_ID,
  REQUEST_EDIT_USERS_BY_EMAIL,
  responsePaymentsByOfficeId,
  responseUsersByOfficeId,
  responseEditUsersByEmail,
  REQUEST_VALIDATE_ACCOUNT_NUMBER,
  responseOfficeConfig,
  responseValidateAccountNumber,
  REQUEST_DO_PAYMENT,
  responseDoPayment,
  REQUEST_REGISTER_VALID_TOKEN,
  responseRegisterValidToken,
  REQUEST_RECOVERY_VALID_TOKEN,
  responseRecoveryValidToken,
  REQUEST_CREATE_ACCOUNT,
  responseCreateAccount,
  REQUEST_LOGIN,
  responseLogin,
  REQUEST_RECOVERY_PASSWORD,
  responseRecoveryPassword,
  REQUEST_NEW_PASSWORD,
  responseNewPassword,
  REQUEST_CHANGE_PASSWORD,
  responseChangePassword,
  REQUEST_ACCOUNTS_BY_USER,
  responseAccountsByUser,
  REQUEST_USER_PROFILE,
  responseUserProfile,
  REQUEST_EDIT_USER_PROFILE,
  responseEditUserProfile,
  REQUEST_DELETE_USER_ACCOUNT,
  responseDeleteUserAccount,
  REQUEST_CARDS_BY_USER,
  responseCardsByUser,
  SEND_EMAIL,
  responseSendEmail,
  REQUEST_ADD_ACCOUNT,
  responseAddAccount,
  REQUEST_EDIT_ACCOUNT,
  responseEditAccount,
  REQUEST_DELETE_ACCOUNT,
  responseDeleteAccount,
  REQUEST_ADD_CARD,
  responseAddCard,
  REQUEST_EDIT_CARD,
  responseEditCard,
  REQUEST_DELETE_CARD,
  responseDeleteCard,
  REQUEST_GET_PAYMENTS_BY_ACCOUNT,
  responseGetPaymentsByAccount,
  REQUEST_GET_RECEIPTS_BY_ACCOUNT,
  responseGetReceiptsByAccount,
  REQUEST_SCHEDULED_PAYMENTS,
  responseScheduledPayments,
  REQUEST_EDIT_SCHEDULED_PAYMENTS,
  responseEditScheduledPayments,
  REQUEST_DELETE_SCHEDULED_PAYMENTS,
  responseDeleteScheduledPayments,
  responseEncryptStringBancomer,
  REQUEST_ENCRYPT_STRING_BANCOMER,
  responseOrdenesPago,
  REQUEST_ORDENES_PAGO,
  responseConsultaCompleta,
  REQUEST_CONSULTA_COMPLETA,
  responseConfirmCode,
  requestSendMensajeToConfirm,
  responseSendMensajeToConfirm,
  REQUEST_CONFIRM_CODE,
  REQUEST_SEND_MESSAJE_TO_CONFIRM,
  responseReporte,
  REQUEST_REPORTE,
  responseContribuyentesPorCuenta,
  REQUEST_GET_CONTRIBUYENTES_POR_CUENTA,
  responseTimbrar,
  REQUEST_TIMBRAR,
  responsePdfFeCfdi,
  responseXmlFeCfdi,
  REQUEST_PDF_FE_CFDI,
  REQUEST_XML_FE_CFDI,
  responseEnviarCorreoCfdi,
  REQUEST_ENVIAR_CORREO_CFDI,
  responseIntentoPago,
  REQUEST_SAVE_INTENTO_PAGO,
  responseComprobantePago,
  REQUEST_REPORTE_COMPROBANTE_PAGO,
  responseEstadoCuenta,
  REQUEST_REPORTE_ESTADO_CUENTA,
  responseGiros,
  REQUEST_GIROS,
  responseGuardarImagenUsuario,
  responseGuardarUsuarioDireccion,
  responseActualizarImagenUsuario,
  responseImagenesUsuario,
  REQUEST_ACTUALIZAR_IMAGEN_USUARIO,
  REQUEST_GUARDAR_IMAGEN_USUARIO,
  REQUEST_IMAGENES_USUARIO,
  responseUsuarioDireccion,
  REQUEST_USUARIO_DIRECCION,
  REQUEST_GUARDAR_PERSONA,
  responseGuardarPersona,
  responseTipoImagenesPersonas,
  REQUEST_TIPO_IMAGENES_PERSONA,
  responseActivarInactivarPersona,
  REQUEST_ACTIVAR_INACTIVAR_PERSONA,
  responseObtenerTipoTramite,
  responseGuardarTramite,
  REQUEST_OBTENER_TIPO_TRAMITE,
  REQUEST_GUARDAR_TRAMITE,
  responseGuardarPersonaDireccion,
  REQUEST_GUARDAR_PERSONA_DIRECCION,
  REQUEST_OBTENER_TRAMITES,
  responseObtenerTipoTramites,
  responseObtenerTramites,
  responseGuardarImagenDireccion,
  REQUEST_GUARDAR_IMAGEN_DIRECCION,
  responseLogPayments,
  REQUEST_LOG_PAYMENTS
} from '../actions/actions'

import {
  getOfficeConfigAPI,
  editOfficeConfigAPI,
  getPaymentsByOfficeIdAPI,
  getUsersByOfficeIdAPI,
  editStatusUserByEmailAPI,
  getAccountNumberAPI,
  savePaymentAPI,
  registerValidTokenAPI,
  recoveryValidTokenAPI,
  createAccountAPI,
  loginAPI,
  recoveryPasswordAPI,
  setNewPasswordAPI,
  changePasswordAPI,
  getAccountsByUserAPI,
  getUserProfileAPI,
  updateUserProfileAPI,
  deleteUserAccountAPI,
  getCardsByUserAPI,
  sendEmailAPI,
  saveAccountAPI,
  updateAccountAPI,
  deleteAccountAPI,
  saveCardAPI,
  updateCardAPI,
  deleteCardAPI,
  getPaymentsByAccountAPI,
  getReceiptsByAccountAPI,
  getScheduledPaymentsAPI,
  updateScheduledPaymentsAPI,
  deleteScheduledPaymentsAPI,
  encryptStringBancomer,
  saveOrdenPagoAPI,
  getConsultaCompletaAPI,
  confirmCodeAPI,
  sendMensajeToConnfirmPhoneAPI,
  getReportAPI,
  getContribuyentePorCuentaAPI,
  timbrarAPI,
  getCfdiPdfAPI,
  getCfdiXmlAPI,
  sendCorreoCFDIAPI,
  saveIntentoPagoAPI,
  getReportComprobantePagoAPI,
  getEstadoCuentaAPI,
  getGirosAPI,
  guardarUsuarioDireccionMonitorAPI,
  actualizarImagenUsuarioAPI,
  guardarImagenUsuarioAPI,
  getImagenesUsuarioAPI,
  getUsuarioDireccionAPI,
  guardarPersonaAPIAPI,
  getTipoImagenesAPI,
  guardarActivarInactivarPersonaAPI,
  obtenertipoTramiteAPI,
  guardarTramiteAPI,
  guardarPersonaDireccionTramiteAPI,
  obtenerTramitesPorSuscriptorAPI,
  guardarImagenDireccionAPI,
  getLogsPaymentsAPI
} from './api'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

function* getOfficeConfig(action) {
  try {
    yield put(showLoading())
    const data = yield call(getOfficeConfigAPI, action.officeId)
    yield put(responseOfficeConfig(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* editOfficeConfig(action) {
  try {
    yield put(showLoading())
    const data = yield call(editOfficeConfigAPI, action.data)
    yield put(responseOfficeConfig(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}
function* getPaymentsByOfficeId(action) {
  try {
    yield put(showLoading())
    const data = yield call(getPaymentsByOfficeIdAPI, action.data)
    yield put(responsePaymentsByOfficeId(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}
function* getUsersByOfficeId(action) {
  try {
    yield put(showLoading())
    const data = yield call(getUsersByOfficeIdAPI, action.data)
    yield put(responseUsersByOfficeId(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}
function* editStatusUserByEmail(action) {
  try {
    yield put(showLoading())
    const data = yield call(editStatusUserByEmailAPI, action.data)
    yield put(responseEditUsersByEmail(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}
function* getAccountNumber(action) {
  try {
    yield put(showLoading())
    const data = yield call(getAccountNumberAPI, action.accountNumber)
    yield put(responseValidateAccountNumber(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* encryptBancomer(action) {
  try {
    yield put(showLoading())
    const data = yield call(encryptStringBancomer, action.data)
    yield put(responseEncryptStringBancomer(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* savePayment(action) {
  try {
    yield put(showLoading())
    const data = yield call(savePaymentAPI, action.data)
    yield put(responseDoPayment(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* registerValidToken(action) {
  try {
    yield put(showLoading())
    const data = yield call(registerValidTokenAPI, action.token)
    yield put(responseRegisterValidToken(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* recoveryValidToken(action) {
  try {
    yield put(showLoading())
    const data = yield call(recoveryValidTokenAPI, action.token)
    yield put(responseRecoveryValidToken(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* createAccount(action) {
  try {
    yield put(showLoading())
    const data = yield call(createAccountAPI, action.data)
    yield put(responseCreateAccount(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* login(action) {
  try {
    yield put(showLoading())
    const data = yield call(loginAPI, action.data)
    yield put(responseLogin(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* recoveryPassword(action) {
  try {
    yield put(showLoading())
    const data = yield call(recoveryPasswordAPI, action.email)
    yield put(responseRecoveryPassword(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* setNewPassword(action) {
  try {
    yield put(showLoading())
    const data = yield call(setNewPasswordAPI, action.data)
    yield put(responseNewPassword(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* changePassword(action) {
  try {
    yield put(showLoading())
    const data = yield call(changePasswordAPI, action.data)
    yield put(responseChangePassword(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* getUserProfile(action) {
  try {
    yield put(showLoading())
    const data = yield call(getUserProfileAPI, action.userId)
    yield put(responseUserProfile(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* updateUserProfile(action) {
  try {
    yield put(showLoading())
    const data = yield call(updateUserProfileAPI, action.data)
    yield put(responseEditUserProfile(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* deleteUserAccount(action) {
  try {
    yield put(showLoading())
    const data = yield call(deleteUserAccountAPI, action.data)
    yield put(responseDeleteUserAccount(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* getAccountsByUser(action) {
  try {
    yield put(showLoading())
    const data = yield call(getAccountsByUserAPI, action.userId)
    yield put(responseAccountsByUser(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

// Cards

function* getCardsByUser(action) {
  try {
    yield put(showLoading())
    const data = yield call(getCardsByUserAPI, action.userId)
    yield put(responseCardsByUser(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* sendEmail(action) {
  try {
    yield put(showLoading())
    const data = yield call(sendEmailAPI, action.data)
    yield put(responseSendEmail(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* saveAccount(action) {
  try {
    yield put(showLoading())
    const data = yield call(saveAccountAPI, action.data)
    yield put(responseAddAccount(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* updateAccount(action) {
  try {
    yield put(showLoading())
    const data = yield call(updateAccountAPI, action.data)
    yield put(responseEditAccount(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* deleteAccount(action) {
  try {
    yield put(showLoading())
    const data = yield call(deleteAccountAPI, action.data)
    yield put(responseDeleteAccount(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

// Cards

function* saveCard(action) {
  try {
    yield put(showLoading())
    const data = yield call(saveCardAPI, action.data)
    yield put(responseAddCard(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* updateCard(action) {
  try {
    yield put(showLoading())
    const data = yield call(updateCardAPI, action.data)
    yield put(responseEditCard(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* deleteCard(action) {
  try {
    yield put(showLoading())
    const data = yield call(deleteCardAPI, action.data)
    yield put(responseDeleteCard(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

// Get payments by account

function* getPaymentsByAccount(action) {
  try {
    yield put(showLoading())
    const data = yield call(getPaymentsByAccountAPI, action.account)
    yield put(responseGetPaymentsByAccount(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

// Get payments by account

function* getReceiptsByAccount(action) {
  try {
    yield put(showLoading())
    const data = yield call(getReceiptsByAccountAPI, action.account)
    yield put(responseGetReceiptsByAccount(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

// Scheduled payments

function* getScheduledPayments(action) {
  try {
    yield put(showLoading())
    const data = yield call(getScheduledPaymentsAPI, action.userId)
    yield put(responseScheduledPayments(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* updateScheduledPayments(action) {
  try {
    yield put(showLoading())
    const data = yield call(updateScheduledPaymentsAPI, action.data)
    yield put(responseEditScheduledPayments(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* deleteScheduledPayments(action) {
  try {
    yield put(showLoading())
    const data = yield call(deleteScheduledPaymentsAPI, action.data)
    yield put(responseDeleteScheduledPayments(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}


function* saveOrdenesPago(action) {
  try {
    yield put(showLoading())
    const data = yield call(saveOrdenPagoAPI, action.data)
    yield put(responseOrdenesPago(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}


function* getConsultaCompleta(action) {
  try {
    yield put(showLoading())
    const data = yield call(getConsultaCompletaAPI, action.data)
    yield put(responseConsultaCompleta(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* confirmCodePhone(action) {
  try {
    yield put(showLoading())
    const data = yield call(confirmCodeAPI, action.data)
    yield put(responseConfirmCode(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* messajeToConfirmPhone(action) {
  try {
    yield put(showLoading())
    const data = yield call(sendMensajeToConnfirmPhoneAPI, action.data)
    yield put(responseSendMensajeToConfirm(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}


function* getReporte(action) {
  try {
    yield put(showLoading())
    const data = yield call(getReportAPI, action.data)
    yield put(responseReporte(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* getContribuyentesPorCuenta(action) {
  try {
    yield put(showLoading())
    const data = yield call(getContribuyentePorCuentaAPI, action.data)
    yield put(responseContribuyentesPorCuenta(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* timbrar(action) {
  try {
    yield put(showLoading())
    const data = yield call(timbrarAPI, action.data)
    yield put(responseTimbrar(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* obtenerFeCfdiPdf(action) {
  try {
    yield put(showLoading())
    const data = yield call(getCfdiPdfAPI, action.data)
    yield put(responsePdfFeCfdi(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* obtenerFeCfdiXml(action) {
  try {
    yield put(showLoading())
    const data = yield call(getCfdiXmlAPI, action.data)
    yield put(responseXmlFeCfdi(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* enviarCorreoCFDI(action) {
  try {
    yield put(showLoading())
    const data = yield call(sendCorreoCFDIAPI, action.data)
    yield put(responseEnviarCorreoCfdi(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* saveIntentoPago(action) {
  try {
    yield put(showLoading())
    const data = yield call(saveIntentoPagoAPI, action.data)
    yield put(responseIntentoPago(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* reporteComprobantePago(action) {
  try {
    yield put(showLoading())
    const data = yield call(getReportComprobantePagoAPI, action.data)
    yield put(responseComprobantePago(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* reporteEstadoCuenta(action) {
  try {
    yield put(showLoading())
    const data = yield call(getEstadoCuentaAPI, action.data)
    yield put(responseEstadoCuenta(data))
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(hideLoading())
  }
}

function* giros(action) {
	try {
		yield put(showLoading())
		const data = yield call(getGirosAPI, action.data)
		yield put(responseGiros(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
	}
}

function* guardarUsuarioDireccionMonitorRequest(action) {
	try {
		yield put(showLoading())
		const data = yield call(guardarPersonaAPIAPI, action.data)
		yield put(responseGuardarPersona(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}


function* actualizarImagenUsuario(action) {
	try {
		yield put(showLoading())
		const data = yield call(actualizarImagenUsuarioAPI, action.data)
		yield put(responseActualizarImagenUsuario(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

function* guardarImagenUsuario(action) {
	try {
		yield put(showLoading())
		const data = yield call(guardarImagenUsuarioAPI, action.data)
		yield put(responseGuardarImagenUsuario(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

function* guardarImagenDireccion(action) {
	try {
		yield put(showLoading())
		const data = yield call(guardarImagenDireccionAPI, action.data)
		yield put(responseGuardarImagenDireccion(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

function* getImagenesUsuario(action) {
	try {
		yield put(showLoading())
		const data = yield call(getImagenesUsuarioAPI, action.data)
		yield put(responseImagenesUsuario(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

function* getTipoImagenes(action) {
	try {
		yield put(showLoading())
		const data = yield call(getTipoImagenesAPI, action.data)
		yield put(responseTipoImagenesPersonas(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

function* getUsuarioDireccion(action) {
	try {
		yield put(showLoading())
		const data = yield call(getUsuarioDireccionAPI, action.data)
		yield put(responseUsuarioDireccion(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

function* getActivarInactivarPersona(action) {
	try {
		yield put(showLoading())
		const data = yield call(guardarActivarInactivarPersonaAPI, action.data)
		yield put(responseActivarInactivarPersona(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

function* obtenerTipoTramite(action) {
	try {
		yield put(showLoading())
		const data = yield call(obtenertipoTramiteAPI, action.data)
		yield put(responseObtenerTipoTramite(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

function* guardarTramite(action) {
	try {
		yield put(showLoading())
		const data = yield call(guardarTramiteAPI, action.data)
		yield put(responseGuardarTramite(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

function* guardarPersonaDireccionTramite(action) {
	try {
		yield put(showLoading())
		const data = yield call(guardarPersonaDireccionTramiteAPI, action.data)
		yield put(responseGuardarPersonaDireccion(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

function* obtenerTramitesPorSuscriptor(action) {
	try {
		yield put(showLoading())
		const data = yield call(obtenerTramitesPorSuscriptorAPI, action.data)
		yield put(responseObtenerTramites(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

function* obtenerLogsPayments(action) {
	try {
		yield put(showLoading())
		const data = yield call(getLogsPaymentsAPI, action.data)
		yield put(responseLogPayments(data))
	} catch (error) {
		yield put(hideLoading())
	} finally {
		yield put(hideLoading())
  	}
}

//

export default function* mySaga() {
  yield all([
    takeLatest(REQUEST_OFFICE_CONFIG, getOfficeConfig),
    takeLatest(REQUEST_EDIT_OFFICE_CONFIG, editOfficeConfig),
    takeLatest(REQUEST_PAYMENTS_BY_OFFICE_ID, getPaymentsByOfficeId),
    takeLatest(REQUEST_USERS_BY_OFFICE_ID, getUsersByOfficeId),
    takeLatest(REQUEST_EDIT_USERS_BY_EMAIL, editStatusUserByEmail),
    takeLatest(REQUEST_VALIDATE_ACCOUNT_NUMBER, getAccountNumber),
    takeLatest(REQUEST_DO_PAYMENT, savePayment),
    takeLatest(REQUEST_REGISTER_VALID_TOKEN, registerValidToken),
    takeLatest(REQUEST_RECOVERY_VALID_TOKEN, recoveryValidToken),
    takeLatest(REQUEST_CREATE_ACCOUNT, createAccount),
    takeLatest(REQUEST_LOGIN, login),
    takeLatest(REQUEST_RECOVERY_PASSWORD, recoveryPassword),
    takeLatest(REQUEST_NEW_PASSWORD, setNewPassword),
    takeLatest(REQUEST_CHANGE_PASSWORD, changePassword),
    takeLatest(REQUEST_ACCOUNTS_BY_USER, getAccountsByUser),
    takeLatest(REQUEST_USER_PROFILE, getUserProfile),
    takeLatest(REQUEST_EDIT_USER_PROFILE, updateUserProfile),
    takeLatest(REQUEST_DELETE_USER_ACCOUNT, deleteUserAccount),
    takeLatest(REQUEST_CARDS_BY_USER, getCardsByUser),
    takeLatest(SEND_EMAIL, sendEmail),
    takeLatest(REQUEST_ADD_ACCOUNT, saveAccount),
    takeLatest(REQUEST_EDIT_ACCOUNT, updateAccount),
    takeLatest(REQUEST_DELETE_ACCOUNT, deleteAccount),
    takeLatest(REQUEST_ADD_CARD, saveCard),
    takeLatest(REQUEST_EDIT_CARD, updateCard),
    takeLatest(REQUEST_DELETE_CARD, deleteCard),
    takeLatest(REQUEST_GET_PAYMENTS_BY_ACCOUNT, getPaymentsByAccount),
    takeLatest(REQUEST_GET_RECEIPTS_BY_ACCOUNT, getReceiptsByAccount),
    takeLatest(REQUEST_SCHEDULED_PAYMENTS, getScheduledPayments),
    takeLatest(REQUEST_EDIT_SCHEDULED_PAYMENTS, updateScheduledPayments),
    takeLatest(REQUEST_DELETE_SCHEDULED_PAYMENTS, deleteScheduledPayments),
    takeLatest(REQUEST_ENCRYPT_STRING_BANCOMER, encryptBancomer),
    takeLatest(REQUEST_ORDENES_PAGO, saveOrdenesPago),
    takeLatest(REQUEST_CONSULTA_COMPLETA, getConsultaCompleta),
    takeLatest(REQUEST_CONFIRM_CODE, confirmCodePhone),
    takeLatest(REQUEST_SEND_MESSAJE_TO_CONFIRM, messajeToConfirmPhone),
    takeLatest(REQUEST_REPORTE, getReporte),
    takeLatest(REQUEST_GET_CONTRIBUYENTES_POR_CUENTA, getContribuyentesPorCuenta),
    takeLatest(REQUEST_TIMBRAR, timbrar),
    takeLatest(REQUEST_PDF_FE_CFDI, obtenerFeCfdiPdf),
    takeLatest(REQUEST_XML_FE_CFDI, obtenerFeCfdiXml),
    takeLatest(REQUEST_ENVIAR_CORREO_CFDI, enviarCorreoCFDI),  
    takeLatest(REQUEST_SAVE_INTENTO_PAGO, saveIntentoPago),  
    takeLatest(REQUEST_REPORTE_COMPROBANTE_PAGO, reporteComprobantePago),
    takeLatest(REQUEST_REPORTE_ESTADO_CUENTA, reporteEstadoCuenta),    
    takeLatest(REQUEST_GIROS, giros),  
    
	  takeLatest(REQUEST_GUARDAR_PERSONA, guardarUsuarioDireccionMonitorRequest),  
    takeLatest(REQUEST_ACTUALIZAR_IMAGEN_USUARIO, actualizarImagenUsuario),
    takeLatest(REQUEST_GUARDAR_IMAGEN_USUARIO, guardarImagenUsuario),    
    takeLatest(REQUEST_IMAGENES_USUARIO, 	getImagenesUsuario),  
    takeLatest(REQUEST_USUARIO_DIRECCION, 	getUsuarioDireccion),  
    takeLatest(REQUEST_TIPO_IMAGENES_PERSONA, 	getTipoImagenes),  
    takeLatest(REQUEST_ACTIVAR_INACTIVAR_PERSONA, 	getActivarInactivarPersona),
    takeLatest(REQUEST_OBTENER_TIPO_TRAMITE, 	obtenerTipoTramite),
    takeLatest(REQUEST_GUARDAR_TRAMITE, 	guardarTramite),
    takeLatest(REQUEST_GUARDAR_PERSONA_DIRECCION, 	guardarPersonaDireccionTramite),
    takeLatest(REQUEST_OBTENER_TRAMITES, 	obtenerTramitesPorSuscriptor),
    takeLatest(REQUEST_GUARDAR_IMAGEN_DIRECCION, 	guardarImagenDireccion),
    

    takeLatest(REQUEST_LOG_PAYMENTS, 	obtenerLogsPayments),
    
    
    
  ])
}
