import Utils from '../resources/Utils'

// Actions
import {
  RESET_APP_STATE,
  RESPONSE_OFFICE_CONFIG,
  RESPONSE_EDIT_OFFICE_CONFIG,
  RESPONSE_PAYMENTS_BY_OFFICE_ID,
  RESPONSE_USERS_BY_OFFICE_ID,
  RESPONSE_EDIT_USERS_BY_EMAIL,
  RESPONSE_VALIDATE_ACCOUNT_NUMBER,
  DELETE_WATER_ACCOUNT,
  ADD_PAYMENT_METHOD,
  RESPONSE_DO_PAYMENT,
  RESPONSE_REGISTER_VALID_TOKEN,
  RESPONSE_RECOVERY_VALID_TOKEN,
  RESPONSE_CREATE_ACCOUNT,
  RESPONSE_RECOVERY_PASSWORD,
  RESPONSE_LOGIN,
  RESPONSE_NEW_PASSWORD,
  RESPONSE_CHANGE_PASSWORD,
  RESPONSE_USER_PROFILE,
  RESPONSE_ACCOUNTS_BY_USER,
  RESPONSE_CARDS_BY_USER,
  ADD_ACCOUNTS_FOR_PAY,
  RESPONSE_SEND_EMAIL,
  RESPONSE_ADD_ACCOUNT,
  RESPONSE_EDIT_ACCOUNT,
  RESPONSE_DELETE_ACCOUNT,
  RESPONSE_ADD_CARD,
  RESPONSE_EDIT_CARD,
  RESPONSE_DELETE_CARD,
  CHECK_ALL_ACCOUNTS,
  CHECK_ACCOUNT,
  RESPONSE_GET_PAYMENTS_BY_ACCOUNT,
  RESPONSE_GET_RECEIPTS_BY_ACCOUNT,
  RESPONSE_SCHEDULED_PAYMENTS,
  RESPONSE_EDIT_SCHEDULED_PAYMENTS,
  RESPONSE_DELETE_SCHEDULED_PAYMENTS,
  RESPONSE_EDIT_USER_PROFILE,
  RESPONSE_DELETE_USER_ACCOUNT,
  EDIT_AMOUNT,
  REQUEST_OPEN_AUTOMATIC_PAYMENT,
  RESPONSE_ENCRYPT_STRING_BANCOMER,
  RESPONSE_ORDENES_PAGO,
  RESPONSE_CONSULTA_COMPLETA,
  RESPONSE_CONFIRM_CODE,
  RESPONSE_SEND_MESSAJE_TO_CONFIRM,
  REQUEST_REPORTE,
  RESPONSE_REPORTE,
  RESPONSE_GET_CONTRIBUYENTES_POR_CUENTA,
  RESPONSE_TIMBRAR,
  RESPONSE_PDF_FE_CFDI,
  RESPONSE_XML_FE_CFDI,
  RESPONSE_ENVIAR_CORREO_CFDI,
  RESPONSE_SAVE_INTENTO_PAGO,
  RESPONSE_REPORTE_COMPROBANTE_PAGO,
  RESPONSE_REPORTE_ESTADO_CUENTA,
  RESPONSE_GIROS,
  RESPONSE_GUARDAR_IMAGEN_USUARIO,
  RESPONSE_ACTUALIZAR_IMAGEN_USUARIO,
  RESPONSE_IMAGENES_USUARIO,
  RESPONSE_GUARDAR_USUARIO_DIRECCION,
  RESPONSE_USUARIO_DIRECCION,
  RESPONSE_GUARDAR_PERSONA,
  RESPONSE_TIPO_IMAGENES_PERSONA,
  RESPONSE_ACTIVAR_INACTIVAR_PERSONA,
  RESPONSE_OBTENER_TIPO_TRAMITE,
  RESPONSE_GUARDAR_TRAMITE,
  RESPONSE_GUARDAR_PERSONA_DIRECCION,
  RESPONSE_OBTENER_TRAMITES,
  RESPONSE_GUARDAR_IMAGEN_DIRECCION,
  RESPONSE_LOG_PAYMENTS
} from '../actions/actions'

import initialState from '../state'

export default function init(state=initialState, action) {
  
  const type = action.type

  switch (type) {

    case RESET_APP_STATE: {
      localStorage.removeItem(Utils.constants.KEY_LS_USER)
      let config = state.config
      initialState.config = config
      initialState.reset = true
      return initialState
    }

    case RESPONSE_OFFICE_CONFIG:
      return Object.assign({}, state, {config: action.data})
    case RESPONSE_PAYMENTS_BY_OFFICE_ID:
        return Object.assign({}, state, {payments: action.data})
    case RESPONSE_USERS_BY_OFFICE_ID:
        return Object.assign({}, state, {users: action.data})
    case RESPONSE_EDIT_USERS_BY_EMAIL: {
          let response = action.data
          if (!Utils.isEmpty(response.data)) {
            let updatedUser = response.data
            state.users.data.data.forEach(function(user, idx) {
              if (updatedUser.email === user.email) {
                state.users.data.data[idx].status = updatedUser.status
              }
            })
            return Object.assign({}, state, {
              users: {
                data: {
                  data: state.users.data.data
                },
                totalpage:this.props.init.users.data.totalpage
              },
            })
          }else{
            return Object.assign({}, state, {errorEditStatusUser:response})
          }
          //return state
        }
    case RESPONSE_VALIDATE_ACCOUNT_NUMBER:
      return Object.assign({}, state, {account: action.data})

    case DELETE_WATER_ACCOUNT:
      return Object.assign({}, state, {account: {}})

    case ADD_PAYMENT_METHOD:
      return Object.assign({}, state, {paymentData: action.data})

    case RESPONSE_DO_PAYMENT:
      return Object.assign({}, state, {payment: action.data})

    case RESPONSE_REGISTER_VALID_TOKEN:
      return Object.assign({}, state, {registerToken: action.data})

    case RESPONSE_RECOVERY_VALID_TOKEN:
      return Object.assign({}, state, {recoveryToken: action.data})

    case RESPONSE_CREATE_ACCOUNT:
      return Object.assign({}, state, {createAccount: action.data})
    case RESPONSE_RECOVERY_PASSWORD:
      return Object.assign({}, state, {recoveryPassword: action.data})

    case RESPONSE_LOGIN: {
      let data = action.data
      localStorage.setItem('user', JSON.stringify(data))
      return Object.assign({}, state, {user: action.data, userId: data.id})
    }

    case RESPONSE_NEW_PASSWORD:{
      let setNewPassword = false
      let type = ''
      if (action.data.status === 'OK') {
        let result = action.data
        if (result.data.newPassword) {
          setNewPassword = true
          type = result.data.type
        }
      }
      return Object.assign({}, state, {newPassword: setNewPassword, newPasswordType: type})
    }

    case RESPONSE_CHANGE_PASSWORD:
      return Object.assign({}, state, {changePassword: action.data})

    case RESPONSE_USER_PROFILE:
      return Object.assign({}, state, {user: {profile: action.data}})

    case RESPONSE_ACCOUNTS_BY_USER: {
      let checkboxes = []
      let accounts = action.data
      if (!Utils.isEmpty(accounts.data)) {
        accounts.data.forEach(function(a) {
          checkboxes.push(false)
        })
      }
      return Object.assign({}, state, {user: {accounts: accounts}, checkboxes: checkboxes, checkall: false})
    }

    case RESPONSE_CARDS_BY_USER:
      return Object.assign({}, state, {user: {cards: action.data}})

    case ADD_ACCOUNTS_FOR_PAY:
      return Object.assign({}, state, {accountsForPay: action.data})

    case RESPONSE_SEND_EMAIL:
      return Object.assign({}, state, {email: action.data})

    case RESPONSE_ADD_ACCOUNT: {
      let response = action.data
      if (!Utils.isEmpty(response.data)) {
        let accounts = state.user.accounts.data || []
        accounts.push(response.data)
        state.checkboxes.push(false)
        return Object.assign({}, state, {
          user: {
            accounts: {
              data: accounts
            }
          },
          addAccount: response,
          checkboxes: state.checkboxes,
          checkall: false
        })
      }
      else {
        return Object.assign({}, state, {errorAddAccount: response})
      }
    }

    case RESPONSE_EDIT_ACCOUNT: {
      let response = action.data
      if (!Utils.isEmpty(response.data)) {
        let updatedAccount = response.data
        state.user.accounts.data.forEach(function(account, idx) {
          if (updatedAccount.accountNumber === account.account) {
            state.user.accounts.data[idx].alias = updatedAccount.alias
            state.user.accounts.data[idx].emojiAlias = updatedAccount.emojiAlias
          }
        })
        return Object.assign({}, state, {
          user: {
            accounts: {
              data: state.user.accounts.data
            },
          },
          editAccount: response
        })
      }
      return state
    }

    case RESPONSE_DELETE_USER_ACCOUNT:
      return Object.assign({}, state, {deleteUserAccount: action.data})

    case RESPONSE_DELETE_ACCOUNT: {
      let response = action.data
      if (!Utils.isEmpty(response.data)) {
        let updatedAccount = response.data
        state.user.accounts.data.forEach(function(account, idx) {
          if (updatedAccount.accountNumber === account.account) {
            state.user.accounts.data.splice(idx, 1);
          }
        })
        return Object.assign({}, state, {
          user: {
            accounts: {
              data: state.user.accounts.data
            }
          },
          deleteAccount: response
        })
      }
      return state
    }

    case RESPONSE_ADD_CARD: {
      let response = action.data
      if (!Utils.isEmpty(response.data)) {
        let cards = state.user.cards.data || []
        cards.push(response.data)
        return Object.assign({}, state, {
          user: {
            cards: {
              data: cards
            }
          },
          addCard: response
        })
      }
      else {
        return Object.assign({}, state, {errorAddCard: response})
      }
    }

    case RESPONSE_EDIT_CARD: {
      let response = action.data
      if (!Utils.isEmpty(response.data)) {
        let updatedCard = response.data
        state.user.cards.data.forEach(function(card, idx) {
          if (updatedCard.id === card.id) {
            state.user.cards.data[idx].alias = updatedCard.alias
          }
        })
        return Object.assign({}, state, {
          user: {
            cards: {
              data: state.user.cards.data
            },
          },
          editCard: response
        })
      }
      return state
    }

    case RESPONSE_DELETE_CARD: {
      let response = action.data
      if (!Utils.isEmpty(response.data)) {
        let updatedCard = response.data
        state.user.cards.data.forEach(function(card, idx) {
          if (updatedCard.id === card.id) {
            state.user.cards.data.splice(idx, 1)
          }
        })
        return Object.assign({}, state, {
          user: {
            cards: {
              data: state.user.cards.data
            }
          },
          deleteCard: response
        })
      }
      return state
    }

    case CHECK_ALL_ACCOUNTS: {
      state.checkboxes.forEach(function(checkbox, idx) {
        return state.checkboxes[idx] = action.status
      })
      return Object.assign({}, state, {checkboxes: state.checkboxes, checkall: action.status})
    }

    case CHECK_ACCOUNT: {
      state.checkboxes[action.idx] = !state.checkboxes[action.idx]
      let checkall = false
      let checkTrueCount = 0
      state.checkboxes.forEach(function(check) {
        if (check)
          checkTrueCount ++
      })
      
      if (checkTrueCount === state.checkboxes.length)
        checkall = true

      return Object.assign({}, state, {checkboxes: state.checkboxes, checkall: checkall})
    }

    case RESPONSE_SCHEDULED_PAYMENTS:
      return Object.assign({}, state, {scheduledPayments: action.data})

    case RESPONSE_EDIT_USER_PROFILE: {
      let response = action.data
      let user = state.user
      user.profile.data.name = response.data.name
      return Object.assign({}, state, {user: user, editUserProfile: action.data})
    }

    // Get payments

    case RESPONSE_GET_PAYMENTS_BY_ACCOUNT: {
      let response = action.data
      return Object.assign({}, state, {payments: response})
    }

    // Get receipts

    case RESPONSE_GET_RECEIPTS_BY_ACCOUNT: {
      let response = action.data
      return Object.assign({}, state, {receipts: response})
    }

    //

    case RESPONSE_EDIT_SCHEDULED_PAYMENTS: {
      let response = action.data
      if (!Utils.isEmpty(response.data)) {
        let updatedScheduled = response.data
        state.scheduledPayments.data.forEach(function(scheduled, idx) {
          if (updatedScheduled.id === scheduled.id) {
            state.scheduledPayments.data[idx].amount = updatedScheduled.amount
          }
        })
        return Object.assign({}, state, {
          scheduledPayments: {
            data: state.scheduledPayments.data
          },
          editScheduledPayment: response
        })
      }
      return state
    }

    case RESPONSE_DELETE_SCHEDULED_PAYMENTS: {
      let response = action.data
      console.log('response')
      console.log(response)
      if (!Utils.isEmpty(response.data)) {
        let updatedScheduled = response.data
        state.scheduledPayments.data.forEach(function(scheduled, idx) {
          if (updatedScheduled.id === scheduled.id) {
            state.scheduledPayments.data.splice(idx, 1);
          }
        })
        return Object.assign({}, state, {
          scheduledPayments: {
            data: state.scheduledPayments.data
          },
          deleteScheduledPayment: response
        })
      }
      return state
    }

    case EDIT_AMOUNT: {
      if (Utils.isUserLoggedIn()) {
        let accountData = {}
        state.accountsForPay.forEach(function(account, idx) {
          if (action.data.accountNumber === account.account) {
            accountData = account
            if (Utils.isEmpty(account.oldValue)) {
              accountData.oldValue = account.amount
            }
            accountData.amount = action.data.amount
            state.accountsForPay[idx] = accountData
          }
        })

        return Object.assign({}, state, {accountsForPay: state.accountsForPay})
      }
      else {
        if (Utils.isEmpty(state.account.data.oldValue)) {
          state.account.data.oldValue = state.account.data.amount
        }
        let account = state.account.data
        account.amount = action.data.amount
        state.account.data = account
        return Object.assign({}, state, {account: {data: account}}) 
      }
    }

    case REQUEST_OPEN_AUTOMATIC_PAYMENT: {
      return Object.assign({}, state, { automaticPayment: action.data}) 
    }

    case RESPONSE_ENCRYPT_STRING_BANCOMER: {
      return Object.assign({}, state, { encryptedStringBancomer: action.data })
    }

    case RESPONSE_ORDENES_PAGO: {
      return Object.assign({}, state, { ordenPago: action.data })
    }

    case RESPONSE_CONSULTA_COMPLETA: {
      return Object.assign({}, state, { consultaCompleta: action.data })
    }

    case RESPONSE_SEND_MESSAJE_TO_CONFIRM: {
      return Object.assign({}, state, { mensajeToConfirm: action.data })
    }

    case RESPONSE_CONFIRM_CODE: {
      return Object.assign({}, state, { confirmCode: action.data })
    }

    case RESPONSE_REPORTE: {
      return Object.assign({}, state, { reporte: action.data })
    }

    case RESPONSE_GET_CONTRIBUYENTES_POR_CUENTA: {
      return Object.assign({}, state, { contribuyentesPorCuenta: action.data })
    }

    case RESPONSE_TIMBRAR: {
      return Object.assign({}, state, { timbrar: action.data })
    }

    case RESPONSE_PDF_FE_CFDI: {
      return Object.assign({}, state, { pdfFeCfdi: action.data })
    }

    case RESPONSE_XML_FE_CFDI: {
      return Object.assign({}, state, { xmlFeCfdi: action.data })
    }

    case RESPONSE_ENVIAR_CORREO_CFDI: {
      return Object.assign({}, state, { enviarCorreoCfdi: action.data })
    }

    case RESPONSE_SAVE_INTENTO_PAGO: {
      return Object.assign({}, state, { intentoPago: action.data })
    }


    case RESPONSE_REPORTE_COMPROBANTE_PAGO: {
      return Object.assign({}, state, { reporteComprobantePago: action.data })
    }

    case RESPONSE_REPORTE_ESTADO_CUENTA: {
      return Object.assign({}, state, { estadoCuenta: action.data })
    }

    case RESPONSE_GIROS: {
      return Object.assign({}, state, { giros: action.data })
    }

	case RESPONSE_GUARDAR_IMAGEN_USUARIO: {
		return Object.assign({}, state, { guardarImagenUsuario: action.data })
	}

	case RESPONSE_ACTUALIZAR_IMAGEN_USUARIO: {
		return Object.assign({}, state, { actualizarImagenUsuario: action.data })
	}

	case RESPONSE_IMAGENES_USUARIO: {
		return Object.assign({}, state, { imagenesUsuario: action.data })
	}

	case RESPONSE_GUARDAR_PERSONA: {
		return Object.assign({}, state, { guardarPersona: action.data })
	}

	case RESPONSE_USUARIO_DIRECCION: {
		return Object.assign({}, state, { usuarioDireccion: action.data })
	}

  case RESPONSE_TIPO_IMAGENES_PERSONA: {
		return Object.assign({}, state, { tipoImagenes: action.data })
	}

  case RESPONSE_ACTIVAR_INACTIVAR_PERSONA: {
		return Object.assign({}, state, { activarInactivarPersona: action.data })
	}

  case RESPONSE_OBTENER_TIPO_TRAMITE: {
		return Object.assign({}, state, { obtenerTipoTramite: action.data })
	}

  case RESPONSE_GUARDAR_TRAMITE: {
		return Object.assign({}, state, { guardarTramite: action.data })
	}

  case RESPONSE_GUARDAR_PERSONA_DIRECCION: {
		return Object.assign({}, state, { guardarPersonaDireccionTramite: action.data })
	}

  case RESPONSE_OBTENER_TRAMITES: {
		return Object.assign({}, state, { obtenerTramites: action.data })
	}

  case RESPONSE_GUARDAR_IMAGEN_DIRECCION: {
		return Object.assign({}, state, { guardarImagenDireccion: action.data })
	}

  case RESPONSE_LOG_PAYMENTS: {
		return Object.assign({}, state, { logsPayments: action.data })
	}
  
  default:
    return state
  }
}
