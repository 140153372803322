import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import './App.css'

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

// Containers
import Home from './containers/Home'
import CreateAccount from './containers/CreateAccount'
import Login from './containers/Login'
import RecoveryPassword from './containers/RecoveryPassword'
import Pay from './containers/Pay'
import AccountList from './containers/AccountList'
import CardList from './containers/CardList'
import ConfirmPayment from './containers/ConfirmPayment'
import Ticket from './containers/Ticket'
import AccountSetting from './containers/AccountSetting'
import CardSetting from './containers/CardSetting'
import Payment from './containers/Payment'
import Receipt from './containers/Receipt'
import Profile from './containers/Profile'
import NotFound from './containers/NotFound'
import BBVAPaymentFailure from "./containers/BBVAPaymentFailure"
import BBVAPaymentSuccess from './containers/BBVAPaymentSuccess'

// Components
import NavBar from './components/NavBar'
import Menu from './components/Menu'
import Footer from './components/Footer'
import AutomaticPayment from './components/AutomaticPayment'

// Utils
import Utils from './resources/Utils'
import { requestOfficeConfig } from './actions/actions'
import PaymentSuite from './containers/PaymentSuite'
import ReceiptSuite from './containers/ReceiptSuite'
import DatosCompletosContrato from './containers/DatosCompletosContrato'
import Tramites from './containers/Tramites'
import LogsPayments from './containers/LogsPayments'
import Configs from './containers/Configs'
import EmployeeLogin from './containers/EmployeeLogin'
import PaymentList from './containers/PaymentList'
import UsersList from './containers/UsersList'

const styles = theme => ({
  root: {
    flexGrow: 1,
    //height: window.innerHeight-25,10000
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  
  content: {
    backgroundColor: 'white',
    padding: theme.spacing(7),
    paddingTop: theme.spacing(14),
    minWidth: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6),
      paddingTop: theme.spacing(14),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(10),
    }
  },
  toolbar: theme.mixins.toolbar,
  checkboxContainer: {
    width: '60%',
    margin: '0 auto',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    }
  },
  checkbox: {
    padding: 0,
    margin: 0,
    marginTop: -4,
    marginRight: 8,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginRight: 4
    }
  },
  checkMarkSuccess: {
    textAlign: 'center'
  },
  checkFromText: {
    display: 'inline-block',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    }
  },
  footerButtons: {
    background: '#F0F7FF',
    position: 'fixed',
    left: 0,
    bottom: 0,
    padding: '24px 0',
    paddingLeft: '10%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5%'
    }
  }
})

class App extends Component {
  constructor(props) {
    super(props)
    this.props.requestOfficeConfig(localStorage.getItem(Utils.constants.KEY_LS_OFFICE))
    this.state = { height: window.innerHeight-50}
  }
  //reegenerar footer de nuevo
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }


  resize() {
    if (window.innerHeight<849) {
      this.setState({ height:849});
    }else if(window.innerWidth<225){
      this.setState({ height:1080});
    }else if(window.innerWidth<225 &&window.innerHeight<849){
      this.setState({ height:1080});
    }else{
    this.setState({ height:window.innerHeight-50});}
  }

  componentWillUnmount() { window.removeEventListener("resize", this.resize.bind(this)); }
  //fin regenerar footer
  showMenu() {
    let pathname = window.location.pathname

    if (pathname === Utils.constants.paths.home ||
      pathname === Utils.constants.paths.register ||
      pathname.split('/')[1] === Utils.constants.paths.registerWithToken.split('/')[1] ||
      pathname === Utils.constants.paths.login ||
      pathname === Utils.constants.paths.loginEmpleado ||
      pathname === Utils.constants.paths.recovery ||
      pathname.split('/')[1] === Utils.constants.paths.pay.split('/')[1] ||
      pathname.split('/')[1] === Utils.constants.paths.recoveryWithToken.split('/')[1] ||
      pathname.split('/')[1] === Utils.constants.paths.bbvaPaymentFailure.split('/')[1] ||
      pathname.split('/')[1] === Utils.constants.paths.bbvaPaymentSuccess.split('/')[1] ||
      pathname.split('/')[1] === Utils.constants.paths.logsPayments.split('/')[1]
    )
      return false
    return true
  }

  showFooter() {
    let pathname = window.location.pathname
    if (pathname === Utils.constants.paths.home ||
      pathname === Utils.constants.paths.register ||
      pathname.split('/')[1] === Utils.constants.paths.registerWithToken.split('/')[1] ||
      pathname === Utils.constants.paths.login ||
      pathname === Utils.constants.paths.recovery ||
      pathname.split('/')[1] === Utils.constants.paths.recoveryWithToken.split('/')[1])
      return true
    return false
  }

  render() {

    const { classes } = this.props
    return (
      <Router>
        <div className={classes.root} style={{ height: this.state.height }} >

          {
            (!Utils.isEmpty(this.props.init.config.data)) ?

              <NavBar
                showMenuButton={this.showMenu()}
                mainLogo={this.props.init.config.data.mainLogo}
                mainTitle={this.props.init.config.data.mainTitle}
              />

              :

              ''
          }

          {
            (this.showMenu() || false) ?
              <Menu />
              :
              ''
          }

          <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.content}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Switch>
                <Route exact path={Utils.constants.paths.home} component={Home} />
                {
                  (!Utils.isUserLoggedIn())?                 <Route exact path={Utils.constants.paths.loginEmpleado} component={EmployeeLogin} />
                  :''
                }
                {
                  (!Utils.isEmpty(this.props.init.config.data)) ?
                      (Utils.isUserEmployeeLoggedIn())?
                      <Route exact path={Utils.constants.paths.configs} component={Configs} />
                      :''
                    :
                    ''
                }
                {
                  (!Utils.isEmpty(this.props.init.config.data)) ?
                    (Utils.isUserEmployeeLoggedIn())?
                      <Route exact path={Utils.constants.paths.allPayments} component={PaymentList} />
                      :''
                  :''
                }
                 {
                  (!Utils.isEmpty(this.props.init.config.data)) ?
                      (Utils.isUserEmployeeLoggedIn())?
                      <Route exact path={Utils.constants.paths.allUsers} component={UsersList} />
                      :''
                    :
                    ''
                }
                <Route exact path={Utils.constants.paths.register} component={CreateAccount} />
                <Route exact path={Utils.constants.paths.registerWithToken} component={CreateAccount} />
                <Route exact path={Utils.constants.paths.login} component={Login} />
                <Route exact path={Utils.constants.paths.recovery} component={RecoveryPassword} />
                <Route exact path={Utils.constants.paths.recoveryWithToken} component={RecoveryPassword} />
                <Route exact path={Utils.constants.paths.pay} component={Pay} />
                <Route exact path={Utils.constants.paths.logsPayments} component={LogsPayments} />
                {
                  (!Utils.isEmpty(this.props.init.config.data)) ?
                    (this.props.init.config.data.fastFlow === 1) ?
                      <Route exact path={Utils.constants.paths.accountById} component={AccountList} />
                      :
                      ''
                    :
                    ''
                }
                <Route exact path={Utils.constants.paths.accounts} component={AccountList} />
                <Route exact path={Utils.constants.paths.cards} component={CardList} />
                <Route exact path={Utils.constants.paths.confirm} component={ConfirmPayment} />
                <Route exact path={Utils.constants.paths.confirmPayment} component={ConfirmPayment} />
                <Route exact path={Utils.constants.paths.ticket} component={Ticket} />
                <Route exact path={Utils.constants.paths.accountSetting} component={AccountSetting} />
                <Route exact path={Utils.constants.paths.cardSetting} component={CardSetting} />
                <Route exact path={Utils.constants.paths.payments} component={Payment} />

                <Route exact path={Utils.constants.paths.paymentsSuiteWithoutAcoount} component={PaymentSuite} />
                <Route exact path={Utils.constants.paths.paymentsSuite} component={PaymentSuite} />

                <Route exact path={Utils.constants.paths.receipts} component={Receipt} />

                <Route exact path={Utils.constants.paths.receiptsSuiteWithoutAcoount} component={ReceiptSuite} />
                <Route exact path={Utils.constants.paths.receiptsSuite} component={ReceiptSuite} />

                <Route exact path={Utils.constants.paths.profile} component={Profile} />
                <Route exact path={Utils.constants.paths.tramites} component={Tramites} />
                <Route exact path={Utils.constants.paths.datosCompletosUsuario} component={DatosCompletosContrato} />

                <Route exact path={Utils.constants.paths.bbvaPaymentFailure} component={BBVAPaymentFailure} />
                <Route exact path={Utils.constants.paths.bbvaPaymentSuccess} component={BBVAPaymentSuccess} />

                {(!Utils.isUserEmployeeLoggedIn())?
                <Route path="*" component={NotFound} />
                :
                    ''}

              </Switch>
              {false ? <AutomaticPayment /> : null}
            </Grid>
          </Grid>

        </div>
        {
          (!Utils.isEmpty(this.props.init.config.data)) ?

            (this.showFooter()) ?
              <Footer
                officeName={this.props.init.config.data.name}
                website={this.props.init.config.data.website}
                urlTerms={this.props.init.config.data.urlTerms}
                urlPrivacy={this.props.init.config.data.urlPrivacy}
              />
              :

              ''
            :

            ''
        }
      </Router>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    requestOfficeConfig: (officeId) => {
      dispatch(requestOfficeConfig(officeId))
    }
  }
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(App)
