import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// Material UI
import { withTheme, withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'

import TableChartIcon from '@material-ui/icons/TableChart';

// Resources
import Utils from '../resources/Utils'
import Emoji1 from '../resources/images/1.png'
import Emoji2 from '../resources/images/2.png'
import Emoji3 from '../resources/images/3.png'
import Emoji4 from '../resources/images/4.png'
import Emoji5 from '../resources/images/5.png'
import Emoji6 from '../resources/images/6.png'
import Emoji7 from '../resources/images/7.png'
import Emoji8 from '../resources/images/8.png'
import Emoji9 from '../resources/images/9.png'
import Emoji10 from '../resources/images/10.png'
import Emoji11 from '../resources/images/11.png'
import Emoji12 from '../resources/images/12.png'
import { TextField } from '@material-ui/core'
import Options from './Options'
import { withRouter } from 'react-router-dom'
import { blue, green, red } from '@material-ui/core/colors'
import { checkAccount } from '../actions/actions'

const styles = theme => ({
  headerTable: {
    margin: 0,
    padding: 0,
    marginTop: 16,
    fontWeight: 700
  },
  headerAmount: {
    margin: 0,
    padding: 0,
    marginTop: 16,
    fontWeight: 700,
    float: 'right',
    width: 100
  },
  smallCell: {
    margin: 0,
    padding: 0
  },
  emojiAlias: {
    marginRight: 8,
    width: 18
  },
  hidden: {
    display: 'none'
  },
  textField: {
    width: '100%',
    margin: '5px',
    textAlignLast: 'end'
  },
})

class AccountTable extends Component {
  constructor(props) {
    super(props)
    this.getAmount = this.getAmount.bind(this)
    this.getEmojiAlias = this.getEmojiAlias.bind(this)
    this.notHaveDebit = this.notHaveDebit.bind(this)
    this.onChangeImporte = this.onChangeImporte.bind(this)
    this.handleOptions = this.handleOptions.bind(this)
    this.openOptions = this.openOptions.bind(this)
    this.state = {
      values:this.props.accounts ? this.props.accounts : [],
      openOptions: false,
      isPositiveBalance: true
    }
  }

  componentDidMount(){
    var self = this;
    this.state.values.map(function(v){
      if(v.amount < 0){
        v.toPay = 0
      } else {
        v.toPay = v.amount
      }
      self.setState({
        isPositiveBalance: Number(v.amount) >= 0
      });
    })
    this.setState({})
  }

  getAmount(idx) {
    let value = this.props.accounts[idx].amount
    return Utils.numberWithCommas(Number(Math.abs(value)).toFixed(2))
  }

  getAmountLabel(idx) {
    let value = this.props.accounts[idx].amount
    return value < 0 ? Utils.getMessages().Accounts.balanceInFavor : "";
  }


  getEmojiAlias(emoji) {
    emoji = Number(emoji)
    if (emoji === 0) {
      return Emoji1
    }
    else if (emoji === 1) {
      return Emoji2
    }
    else if (emoji === 2) {
      return Emoji3
    }
    else if (emoji === 3) {
      return Emoji4
    }
    else if (emoji === 4) {
      return Emoji5
    }
    else if (emoji === 5) {
      return Emoji6
    }
    else if (emoji === 6) {
      return Emoji7
    }
    else if (emoji === 7) {
      return Emoji8
    }
    else if (emoji === 8) {
      return Emoji9
    }
    else if (emoji === 9) {
      return Emoji10
    }
    else if (emoji === 10) {
      return Emoji11
    }
    else {
      return Emoji12
    }
  }

  notHaveDebit(idx) {
    if (Number(this.props.accounts[idx].amount) <= 0 && !this.props.init.config.data.positiveBalance)
      return true
    return false
  }

  onChangeBlurImporte(e, idx){
    let accounts = this.props.accounts
    if(e.target.value >= 0){
      accounts[idx].toPay = parseFloat(e.target.value)

      if(e.target.value < accounts[idx].amount){
        accounts[idx].toPay = parseFloat(accounts[idx].amount)
      }

      this.setState({ values:  accounts })

      if(parseFloat(e.target.value) === 0){
        if(this.props.checkboxChecked[idx]){
          this.props.checkAccount(idx)
        }  
      }
    }

  }

  onChangeImporte(e, idx){
    let accounts = this.props.accounts
    if(e.target.value >= 0){
      accounts[idx].toPay = parseFloat(e.target.value)

      this.setState({ values:  accounts })

      if(parseFloat(e.target.value) === 0){
        if(this.props.checkboxChecked[idx]){
          this.props.checkAccount(idx)
        }  
      }
    }

  }

  openOptions(idx) {
    this.setState({openOptions: true})
  }

  handleOptions(idx, option) {
    if (option === 'Pagos') {
      const { account } = this.state.values[idx]
      this.props.history.push("/pagosSuite/" + account)
    }
    else if (option === 'Recibos') {
      const { account } = this.state.values[idx]
      this.props.history.push("/recibosSuite/" + account)
    }
  }

  render() {

    const { classes } = this.props
    const { isPositiveBalance } = this.state;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Accounts.accountNumber}</Typography></TableCell>
            <Hidden xsDown>
              <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Accounts.name}</Typography></TableCell>
            </Hidden>
            <Hidden mdDown>
            {
              (Utils.isUserLoggedIn()) ?
                <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Accounts.alias}</Typography></TableCell>
              :
                <TableCell className={classes.hidden}></TableCell>
            }
            </Hidden>
            <Hidden smDown>
            <TableCell><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Accounts.address}</Typography></TableCell>
            {
              (Utils.isUserLoggedIn()) ?
                (!Utils.isEmpty(this.props.checkboxCheckedAll)) ?
                <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Accounts.dueDate}</Typography></TableCell>
                :
                <TableCell className={classes.hidden}></TableCell>
              :
              <TableCell className={classes.smallCell}><Typography variant="subtitle2" className={classes.headerTable}>{Utils.getMessages().Accounts.dueDate}</Typography></TableCell>
            }
            </Hidden>
            {
              (Utils.isUserLoggedIn()) ?
                (!Utils.isEmpty(this.props.checkboxCheckedAll)) ?
                <TableCell numeric style={{padding: 0}}><Typography variant="subtitle2" className={classes.headerAmount}>{Utils.getMessages().Accounts.balance}</Typography></TableCell>
                :
                <TableCell className={classes.hidden}></TableCell>
              :
              <TableCell numeric style={{padding: 0}}><Typography variant="subtitle2" className={classes.headerAmount}>{isPositiveBalance ?  Utils.getMessages().Accounts.balance : "Saldo a Favor" }</Typography></TableCell>
            }
            {
              (Utils.isUserLoggedIn()) ?
                (!Utils.isEmpty(this.props.checkboxCheckedAll)) ?
                <TableCell numeric style={{padding: 0}}><Typography variant="subtitle2" className={classes.headerAmount}>{Utils.getMessages().Accounts.toPay}</Typography></TableCell>
                :
                <TableCell className={classes.hidden}></TableCell>
              :
              <TableCell numeric style={{padding: 0}}><Typography variant="subtitle2" className={classes.headerAmount}>{ Utils.getMessages().Accounts.toPay }</Typography></TableCell>
            }
            {
              (Utils.isUserLoggedIn()) ?
                (!Utils.isEmpty(this.props.checkboxCheckedAll)) && false ?
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={this.props.checkboxCheckedAll}
                      onClick={ (event) => { this.props.onClick('checkbox-all', event) }}
                    />
                  </TableCell>

                :

                <TableCell></TableCell>

              :

                <TableCell className={classes.hidden}></TableCell>

            }
          </TableRow>
        </TableHead>
      <TableBody>
      {
        this.props.accounts.map((account, idx) => {
        
          return (
            <TableRow key={idx}>
              <TableCell className={classes.smallCell}>
                <Typography variant="body2">{account.account}</Typography>
              </TableCell>
              <Hidden xsDown>
                <TableCell><Typography variant="body2">{account.name}</Typography></TableCell>
              </Hidden>
              <Hidden mdDown>
              {
                (Utils.isUserLoggedIn()) ?
                  <TableCell><Typography variant="body2"><img className={classes.emojiAlias} alt="" src={this.getEmojiAlias(account.emojiAlias)} /><label>{account.alias}</label></Typography></TableCell>
                :
                  <TableCell className={classes.hidden}></TableCell>
              }
              </Hidden>
              <Hidden smDown>
              <TableCell><Typography variant="body2">{account.address}</Typography></TableCell>
              {
                (Utils.isUserLoggedIn()) ?
                  (!Utils.isEmpty(this.props.checkboxChecked)) ?
                    <TableCell className={classes.smallCell}><Typography variant="body2">{account.expirationAt}</Typography></TableCell>
                  :
                  <TableCell className={classes.hidden}></TableCell>
                :
                <TableCell className={classes.smallCell}><Typography variant="body2">{account.expirationAt}</Typography></TableCell>
              }
              </Hidden>
              {
                (Utils.isUserLoggedIn()) ?
                  (!Utils.isEmpty(this.props.checkboxChecked)) ?
                    <TableCell numeric className={classes.smallCell}>
                      <Typography variant="body2">$ {this.getAmount(idx)}</Typography>
                      <Typography variant="caption" color='primary'>{this.getAmountLabel(idx)}</Typography> 
                    </TableCell>
                  :
                  <TableCell className={classes.hidden}></TableCell>
                :
                <TableCell numeric className={classes.smallCell}><Typography variant="body2">$ {this.getAmount(idx)}</Typography></TableCell>
              }
              {
                (Utils.isUserLoggedIn()) ?
                  (!Utils.isEmpty(this.props.checkboxChecked)) ?
                    <TableCell numeric className={classes.smallCell}>
                        {
                          // not allow parcial payments
                          (true) ?
                          (
                          <TextField
                            type="number"
                            value={account.toPay}
                            onChange={ (event ) => { this.onChangeImporte(event, idx) } }
                            onBlur={ (event ) => { this.onChangeBlurImporte(event, idx) } }
                            className={ classes.textField }
                          />
                          ) : account.toPay
                        }
                        
                    </TableCell>
                  :
                  <TableCell className={classes.hidden}></TableCell>
                :
                <TableCell numeric className={classes.smallCell}>
                    {
                      (true) ?
                      (
                      <TextField
                        type="number"
                        value={account.toPay}
                        onChange={ (event ) => { this.onChangeImporte(event, idx) } }
                        onBlur={ (event ) => { this.onChangeBlurImporte(event, idx) } }
                        className={ classes.textField }
                      />
                      ) : account.toPay
                    }
                </TableCell>
              }
              {
                (Utils.isUserLoggedIn()) ?
                  (!Utils.isEmpty(this.props.checkboxChecked)) ?
                    <TableCell>
                      <Checkbox
                        disabled={this.notHaveDebit(idx)}
                        color="primary"
                        checked={this.props.checkboxChecked[idx]}
                        onClick={ (event) => { this.props.onClick('checkbox-'+idx, event) } }
                      />
                    </TableCell>

                  :
                  
                  <TableCell>
                    <Grid container>
                      {
                        false ?
                        <Grid item lg={2}>
                        <IconButton 
                          style={{ color:green["500"] }}
                          onClick={() => { this.props.onShowInfo(idx) }}>
                          <InfoIcon />
                        </IconButton>
                      </Grid>: null
                      }

                      <Grid item lg={2} style={{margin:'0 10% 0 0'}}>
                        <IconButton onClick={() => { this.props.onEdit(idx) }} >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                      <Grid item lg={2}style={{margin:'0 0 0 10%'}}>
                        <IconButton 
                            style={{ color:red["500"] }}
                            onClick={() => { this.props.onDelete(idx) }}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                      {
                        false ?
                        <Grid item lg={2}>
                        <IconButton
                          style={{ color:blue["500"] }}
                          title={"Descargar estado de cuenta"}
                          onClick={() => { this.props.onDownloadEstadoCuenta(account) }}>
                          <TableChartIcon />
                        </IconButton>
                      </Grid> : null
                      }
                      
                      {
                        false ? 
                        <Grid item lg={2}>
                        <Options
                            idx={idx}
                            open={this.state.openOptions}
                            options={[ "Pagos", "Recibos" ]}
                            handleCloseOptions={this.handleOptions}
                        />
                      </Grid> : null
                      }
                     

                    </Grid>
                  </TableCell>

                :

                <TableCell className={classes.hidden}></TableCell>
              }
            </TableRow>
          )
        })
      }
      </TableBody>
    </Table>
    )
  }
}

const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => {
  return {
    checkAccount: (idx) => {
      dispatch(checkAccount(idx))
    },
  }
}

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AccountTable)
